<template>
  <el-container>
    <!--  顶部信息-->
    <el-header class="front-header">
      <frontHead></frontHead>
    </el-header>
    <el-main class="front-main">
      <div class="main-box">
        <!-- 导航-->
        <el-breadcrumb separator="/" style="margin-bottom: 20px;font-size: 16px;">
          <el-breadcrumb-item :to="{ path: '/' }"><el-icon class="el-icon-location" size="16"></el-icon>&nbsp;首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{path:'/channel',query:{parentChannelId:parentChannelId,parentChannelName:parentChannelName,channelId:channelId,channelName:channelName}}">{{
              channelName
            }}
          </el-breadcrumb-item>
          <el-breadcrumb-item>文章内容</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 正文-->
        <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span class="title">{{content.title}}</span>
            </div>
          </template>
          <div class="time">
            <span>发布时间：{{ content.time }}</span>
            <span>浏览：{{content.clicks+1}}</span>
          </div>
          <div class="content" v-html="content.content">
          </div>
        </el-card>

        <!-- 上一篇下一篇 -->
        <el-card class="box-card" style="margin-top: 5px">
          <div slot="header" class="clearfix">

              <span v-if="contentPrev==null"><el-link disabled>没有上一篇了</el-link></span>
              <span v-else>
                <el-link type="primary" @click="toContentPrevNext(contentPrev.id, channelName, channelId,)">
                  上一篇：{{contentPrev.title}}
                </el-link>
              </span>

            <span v-if="contentNext==null" style="float:right;"><el-link disabled>没有下一篇了</el-link></span>
            <span v-else style="float:right;">
              <el-link type="primary" @click="toContentPrevNext(contentNext.id, channelName, channelId)">
                下一篇：{{contentNext.title}}
                </el-link>
            </span>
          </div>

        </el-card>
      </div>
    </el-main>
    <!--  底部信息-->
    <el-footer class="front-footer">
      <frontFooter></frontFooter>
    </el-footer>
  </el-container>
</template>

<script>
import frontHead from "@/views/front/head";
import frontFooter from "@/views/front/footer";
import Mock from "mockjs";
import contentApi from "@/api/info/content";
import replaceEditorImgURL from '@/utils/replaceEditorImgURL'
export default {
  name: "content",
  components: {
    frontHead,
    frontFooter
  },
  data() {
    return {
      contentId: '',
      channelId: '',
      channelName: '',
      parentChannelId: '',
      parentChannelName: '',
      content:{},
      clicks:'',
      contentPrev:{
        id:'',
        title:''
      }
      ,
      contentNext:{
        id:'',
        title:''
      }
    }
  },
  mounted() {
    this.contentId = this.$route.query.contentId;
    this.channelId = this.$route.query.channelId;
    this.channelName = this.$route.query.channelName;
    this.parentChannelId = this.$route.query.parentChannelId;
    this.parentChannelName = this.$route.query.parentChannelName;
    this.queryDetailsContent();

    this.addClicks();
  },
  methods: {
    toContentPrevNext(id, channelName, channelId){
      this.contentId = this.content.id;
        this.$router.push({
          path: '/content',
          query: {
              contentId:id,
              channelId:channelId,
              channelName:channelName
          }
        });
      this.addClicks();
        //返回顶部
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0;
    },
    // 新增点击量
    addClicks(){
      contentApi.addClicksContent(this.contentId);
    },
    // 查询详情内容
    queryDetailsContent(){
      contentApi.queryDetailsContent(this.contentId, this.channelId).then(res => {
          this.content =res.data.data.data;
          if(res.data.data.data.content != null) {
            this.content.content = replaceEditorImgURL.toReplaceEditorImgURL(res.data.data.data.content);
          }
          this.contentPrev = res.data.data.contentPrev;
          this.contentNext = res.data.data.contentNext;
      })
    }
  },
  watch:{
    $route: { // 监听路由的参数变化
      handler () { // 数据变化更新数据
        console.log("path",this.$route.path)
        if(this.$route.path == '/content') {
          this.contentId = this.$route.query.contentId;
          this.queryDetailsContent();
        }
      },
      // immediate: true // 立即执行
    }
  }
}
</script>

<style scoped>
.main-box{
  width: 1240px;
  margin: 0 auto;
  color: #FFFFFF;
  padding: 20px;
  background-color: #000000;
  min-height: 1000px;
}
/deep/.el-breadcrumb__inner{
  background-color: #000000;
  color: #FFFFFF
}
.box-card{
  background-color: #000000;
  border: none;
  color: #FFFFFF;
  border: #757c84 solid 1px;
}
.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #409eff;
  font-size: 18px;
}
.title{
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
}
.time{
  text-align: center;
}
.time span{
  margin-left: 15px;
}
/deep/.content p{
  /*text-indent: 2em;*/
  /*line-height: 30px;*/
  margin: 10px 0;
}
</style>